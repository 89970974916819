import { Stack } from "@mui/material";
import { MenuItemStyled } from "../../../../shared/components/MenuItemStyled";
import { FieldConfiguration, ReportField } from "../../../../shared/reporting/api/biClient.types";
import CheckboxField from "../../common/CheckboxField";
import SwitchMenuItem from "./measures/menuItems/SwitchMenuItem";

interface Props {
  field: ReportField;
  showExcludeZeroValues: boolean;
  onChanged: (changes: Partial<FieldConfiguration>) => void;
}

const SuppressBlankValues = ({ field, onChanged, showExcludeZeroValues }: Props) => {
  const hideBlankValue = Boolean(field.config.hideBlankValues);
  const hideZeroValues = Boolean(field.config.hideZeroValues);

  const handleChanged = (changes: Partial<FieldConfiguration>) => {
    const resetHideZeroValues = changes.hideBlankValues === false ? false : changes.hideZeroValues;
    onChanged({ ...changes, hideZeroValues: resetHideZeroValues });
  };

  return (
    <Stack>
      <SwitchMenuItem
        caption="Suppress Blank Values"
        value={hideBlankValue}
        onClick={() => handleChanged({ hideBlankValues: !hideBlankValue })}
      />
      {showExcludeZeroValues && (
        <MenuItemStyled sx={{ py: 0 }}>
          <CheckboxField
            label="Treat Zeros as Empty"
            checked={hideZeroValues}
            onChange={(value) => handleChanged({ hideZeroValues: value })}
            formControlLabelSx={{ ml: 1.25 }}
          />
        </MenuItemStyled>
      )}
    </Stack>
  );
};

export default SuppressBlankValues;
