import {
  AmountType,
  CalculateByField,
  FieldConfigurationType,
  IrrCashFlowFrequency,
  MeasureDateRange,
  MeasureUnit,
  NumberFormatterOptions,
  ReportField,
} from "../../../../shared/reporting/api/biClient.types";
import { getMeasureAmountTypeField, getMeasureCalculateByField } from "./isConfigurationValid";
import { isMeasure } from "./isMeasure";

const getTrackedMeasureProperties = (measures: ReportField[]): MeasureInfoTracked[] => {
  return measures.map((measure): MeasureInfoTracked => {
    const field: MeasureInfoTracked = {
      guid: measure.config.guid,
      dateRange: measure.config.dateRange,
      standalone: measure.config.standalone,
      calculateByField: getMeasureCalculateByField(measure),
      blankZero: measure.config.blankZero,
      reverseSign: measure.config.reverseSign,
      customLabel: measure.config.customLabel,
      format: measure.config.format,
      cashFlowFrequency:
        measure.config.type === FieldConfigurationType.Irr ? measure.config.cashFlowFrequency : undefined,
      hideBlankValues: measure.config.hideBlankValues,
      hideZeroValues: measure.config.hideZeroValues,
    };

    if (isMeasure(measure)) {
      field.units = measure.meta.units;
      field.amountType = getMeasureAmountTypeField(measure);
    }

    return field;
  });
};

export type MeasureInfoTracked = {
  guid: string;
  dateRange: MeasureDateRange | undefined | null;
  standalone: boolean | undefined | null;
  calculateByField: CalculateByField | undefined | null;
  units?:
    | {
        [key: string]: MeasureUnit;
      }
    | undefined
    | null;
  blankZero: boolean | undefined | null;
  reverseSign: boolean | undefined | null;
  customLabel: string | undefined | null;
  format: NumberFormatterOptions | undefined | null;
  cashFlowFrequency: IrrCashFlowFrequency | undefined | null;
  amountType?: AmountType | undefined | null;
  hideBlankValues?: boolean | undefined | null;
  hideZeroValues?: boolean | undefined | null;
};

export default getTrackedMeasureProperties;
