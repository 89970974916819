import { Divider, Grid, Stack, Typography } from "@mui/material";
import { useCallback } from "react";
import { FieldConfiguration, ReportField } from "../../../../../../shared/reporting/api/biClient.types";
import FieldOptionDeleteButton from "../../../../common/FieldOptionDeleteButton";
import CustomLabel from "../../../../common/customLabel/CustomLabel";
import FieldPopover from "../../../common/fields/FieldPopover";
import AmountTypeMenuItem from "../../../common/measures/menuItems/AmountTypeMenuItem";
import BlankZeroMenuItem from "../../../common/measures/menuItems/BlankZeroMenuItem";
import CashFlowFrequencyMenuItem from "../../../common/measures/menuItems/CashFlowFrequencyMenuItem";
import ChartOfAccountsItem from "../../../common/measures/menuItems/ChartOfAccountsItem";
import CustomConditionsMenuItem from "../../../common/measures/menuItems/CustomConditionsMenuItem";
import ExplainMenuItem from "../../../common/measures/menuItems/ExplainMenuItem";
import PeriodMenuItem from "../../../common/measures/menuItems/PeriodMenuItem";
import ReverseSignMenuItem from "../../../common/measures/menuItems/ReverseSignMenuItem";
import TransferFieldMenuItem from "../../../common/measures/menuItems/TransferFieldMenuItem";
import ValueFieldsMenuItem from "../../../common/measures/menuItems/ValueFieldsMenuItem";
import { isGlTableMeasure, isSharesRelatedMeasure } from "../../../utils/MeasureUtils";
import { isMeasure } from "../../../utils/isMeasure";
import PivotFieldMenuItem from "./menuItems/PivotFieldMenuItem";
import SuppressBlankValues from "../../../common/SuppressBlankValues";

interface Props {
  anchorEl: HTMLElement | null;
  measure: ReportField;
  cancel: () => void;
  saveChanges: (changes: Partial<FieldConfiguration>, causeClosing: boolean) => void;
  saveAllowCustomConditions: (useCustomConditions: boolean) => void;
  onRemove: () => void;
}

export default function MeasureFieldOptionPopup(props: Props) {
  const { anchorEl, measure, cancel, saveChanges, saveAllowCustomConditions, onRemove } = props;

  const isTheFieldMeasure = isMeasure(measure);
  const amountTypeChangeDisabled = !isTheFieldMeasure || !isGlTableMeasure(measure.meta);
  const hideAmountRelatedControls = isTheFieldMeasure && isSharesRelatedMeasure(measure);

  const handleOnRemove = useCallback(() => {
    onRemove();
    cancel();
  }, [onRemove, cancel]);

  const handleSuppressValues = useCallback(
    (changes: Partial<FieldConfiguration>) => {
      saveChanges(changes, false);
    },
    [saveChanges]
  );

  return (
    <FieldPopover open={true} anchorEl={anchorEl || undefined} sx={{ width: 400, p: 0 }} onClose={cancel}>
      <CustomLabel
        customLabelValue={measure.config.customLabel}
        originalName={measure.meta.caption}
        updateCustomLabel={(label) => saveChanges({ customLabel: label }, false)}
      />
      <OptionDivider />
      {isTheFieldMeasure && <ExplainMenuItem measure={measure} onClose={cancel} />}
      {isTheFieldMeasure && <ChartOfAccountsItem measure={measure} onClose={cancel} />}
      <OptionDivider />
      <Grid sx={{ display: "flex", flexDirection: "column", flex: 1, gap: 1.5, px: 2, py: 1.5 }}>
        <PeriodMenuItem measure={measure} saveChanges={saveChanges} onClose={cancel} />
        {isTheFieldMeasure && <TransferFieldMenuItem measure={measure} onUpdate={saveChanges} />}
        {!hideAmountRelatedControls && isTheFieldMeasure && (
          <>
            <AmountTypeMenuItem measure={measure} saveChanges={saveChanges} disabled={amountTypeChangeDisabled} />
            <ValueFieldsMenuItem measure={measure} saveChanges={saveChanges} />
          </>
        )}
        {isTheFieldMeasure && (
          <CashFlowFrequencyMenuItem measure={measure} saveChanges={saveChanges} onClose={cancel} />
        )}
      </Grid>
      <OptionDivider />
      <Stack py={1.5}>
        <Typography variant="subtitle1" sx={{ pl: 2, py: 1 }}>
          Other
        </Typography>
        <CustomConditionsMenuItem measure={measure} saveAllowCustomConditions={saveAllowCustomConditions} />
        <BlankZeroMenuItem measure={measure} saveChanges={saveChanges} />
        <ReverseSignMenuItem measure={measure} saveChanges={saveChanges} />
        <PivotFieldMenuItem measure={measure} saveChanges={saveChanges} />
        <SuppressBlankValues
          field={measure}
          onChanged={handleSuppressValues}
          showExcludeZeroValues={measure.config.hideBlankValues === true}
        />
      </Stack>
      <OptionDivider />
      <FieldOptionDeleteButton onRemove={handleOnRemove} />
    </FieldPopover>
  );
}

const OptionDivider = () => <Divider style={{ marginBottom: 0, marginTop: 0 }} />;
