/* eslint-disable no-console */
import { deepClone } from "@mui/x-data-grid/utils/utils";
import {
  AreaItemType,
  ConditionConfiguration,
  DimensionDescriptor,
  DimensionField,
  DimensionFieldType,
  FieldConfiguration,
  MeasureDateRange,
  MeasureDescriptor,
  PivotConfiguration,
  PivotGeneralSettings,
  ReportField,
  SortConfiguration,
} from "../../../../../shared/reporting/api/biClient.types";
import { createFieldId } from "../../common/utilities/createFieldId";
import { valueToConfiguration } from "../../common/utilities/valueToConfiguration";
import { ConditionField, SortField } from "../../Types";

export const createReportConfiguration = (
  conditions: ConditionField[],
  rows: DimensionField[],
  columns: DimensionField[],
  values: ReportField[],
  sorts: SortField[],
  settings: PivotGeneralSettings
) => {
  const reportConfiguration: PivotConfiguration = {
    conditions: conditions.map((c) => c.config),
    rows: rows.map((r) => r.config),
    columns: columns.map((c) => c.config),
    values: values.map(valueToConfiguration),
    sort: sorts.map((s) => s.config),
    settings: { ...settings },
  };
  return reportConfiguration;
};

export const configurationToConditions = (
  configConditions: ConditionConfiguration[],
  dimensions: DimensionDescriptor[]
): ConditionField[] => {
  try {
    const conditions = configConditions
      .map((config) => {
        const dimension = dimensions.find((d) => d.name === config.filter.dimensionName);
        if (dimension) {
          const copied = { config, meta: dimension, areaItemType: AreaItemType.CONDITIONS } as ConditionField;
          // backward compatibility with saved old reports
          if (copied.config.guid === undefined) {
            copied.config.guid = createFieldId(dimension.name);
          }
          return copied;
        } else {
          console.log(`Field ${config.filter.dimensionName} is not found in the dimensions' list`);
        }
        return undefined;
      })
      .filter((c): c is ConditionField => c !== undefined);
    return conditions;
  } catch {
    return [];
  }
};

export const configurationToRows = (configRows: FieldConfiguration[], dimensions: DimensionDescriptor[]) => {
  try {
    const rows = configRows
      .map((config) => {
        const dimension = dimensions.find((d) => d.name === config.name);
        if (dimension) {
          const copied: DimensionField = {
            type: DimensionFieldType.DIMENSION,
            config,
            meta: dimension,
            areaItemType: AreaItemType.ROWS,
          };
          return copied;
        } else {
          console.log(`Field ${config.name} is not found in the dimensions' list`);
        }
        return undefined;
      })
      .filter((c): c is DimensionField => !!c);
    return rows;
  } catch {
    return [];
  }
};

export const configurationToColumns = (configColumns: FieldConfiguration[], dimensions: DimensionDescriptor[]) => {
  try {
    const columns = configColumns
      .map((config) => {
        const dimension = dimensions.find((d) => d.name === config.name);
        if (dimension) {
          const copied: DimensionField = {
            type: DimensionFieldType.DIMENSION,
            config,
            meta: dimension,
            areaItemType: AreaItemType.COLUMNS,
          };
          return copied;
        } else {
          console.log(`Field ${config.name} is not found in the dimensions' list`);
        }
        return undefined;
      })
      .filter((c): c is DimensionField => !!c);
    return columns;
  } catch {
    return [];
  }
};

export const configurationToValues = (configValues: FieldConfiguration[], measures: MeasureDescriptor[]) => {
  try {
    const values = configValues
      .map((config) => {
        const measure = measures.find((d) => d.name === config.name);
        if (measure) {
          const copied: ReportField = {
            type: DimensionFieldType.MEASURE,
            config: {
              ...config,
              format:
                config.format ||
                (measure?.defaultFormat?.formatType === "Numeric" ? measure?.defaultFormat : undefined),
              dateRange: config.dateRange || MeasureDateRange.EndingBalance,
            },
            meta: measure,
            areaItemType: AreaItemType.VALUES,
          };
          return copied;
        } else {
          console.log(`Field ${config.name} is not found in the measures' list`);
        }
        return undefined;
      })
      .filter((c) => c !== undefined) as ReportField[];
    return values;
  } catch {
    return [];
  }
};

export const configurationToSorts = (configSorts: SortConfiguration[], dimensions: DimensionDescriptor[]) => {
  try {
    const sorts = configSorts
      .map((config) => {
        const dimension = dimensions.find((d) => d.name === config.name);
        if (dimension) {
          const copied: SortField = {
            config,
            meta: dimension,
            areaItemType: AreaItemType.SORTS,
          };
          return copied;
        } else {
          console.log(`Field ${config.name} is not found in the dimensions' list`);
        }
        return undefined;
      })
      .filter((c) => c !== undefined) as SortField[];
    return sorts;
  } catch {
    return [];
  }
};

export const configurationToSettings = (settings: PivotGeneralSettings) => {
  try {
    const copiedSettings = deepClone(settings);
    return copiedSettings;
  } catch {
    return {};
  }
};
