import { Divider, Grid, Stack, Typography } from "@mui/material";
import { useCallback } from "react";
import { FieldConfiguration, ReportField } from "../../../../../shared/reporting/api/biClient.types";
import CustomLabel from "../../../common/customLabel/CustomLabel";
import FieldOptionDeleteButton from "../../../common/FieldOptionDeleteButton";
import FieldPopover from "../../common/fields/FieldPopover";
import AmountTypeMenuItem from "../../common/measures/menuItems/AmountTypeMenuItem";
import BlankZeroMenuItem from "../../common/measures/menuItems/BlankZeroMenuItem";
import CashFlowFrequencyMenuItem from "../../common/measures/menuItems/CashFlowFrequencyMenuItem";
import ChartOfAccountsItem from "../../common/measures/menuItems/ChartOfAccountsItem";
import CustomConditionsMenuItem from "../../common/measures/menuItems/CustomConditionsMenuItem";
import ExplainMenuItem from "../../common/measures/menuItems/ExplainMenuItem";
import ReverseSignMenuItem from "../../common/measures/menuItems/ReverseSignMenuItem";
import TransferFieldMenuItem from "../../common/measures/menuItems/TransferFieldMenuItem";
import ValueFieldsMenuItem from "../../common/measures/menuItems/ValueFieldsMenuItem";
import { isMeasure } from "../../utils/isMeasure";
import { isGlTableMeasure, isSharesRelatedMeasure } from "../../utils/MeasureUtils";
import PeriodMenuItem from "./PeriodMenuItem";
import SuppressBlankValues from "../../common/SuppressBlankValues";

interface Props {
  anchorEl: HTMLElement | null;
  measure: ReportField;
  cancel: () => void;
  saveChanges: (changes: Partial<FieldConfiguration>, causeClosing: boolean) => void;
  saveAllowCustomConditions: (useCustomConditions: boolean) => void;
  onRemove: () => void;
}

export default function MeasureFieldOptionPopup(props: Props) {
  const { anchorEl, measure, cancel, saveChanges, saveAllowCustomConditions, onRemove } = props;

  const updateCustomLabel = useCallback(
    (customLabel: string | undefined) => {
      saveChanges({ customLabel }, false);
    },
    [saveChanges]
  );

  const handleRemove = useCallback(() => {
    onRemove();
    cancel();
  }, [cancel, onRemove]);

  const isTheFieldMeasure = isMeasure(measure);
  const amountTypeChangeDisabled = !isTheFieldMeasure || !isGlTableMeasure(measure.meta);
  const isSharesMeasure = isTheFieldMeasure && isSharesRelatedMeasure(measure);
  const showAmountRelatedMeasures =
    !isSharesMeasure &&
    isTheFieldMeasure &&
    measure.meta.capabilities.isConfigurable &&
    measure.meta.capabilities.changeAmountField;

  const handleSuppressValues = useCallback(
    (changes: Partial<FieldConfiguration>) => {
      saveChanges(changes, false);
    },
    [saveChanges]
  );

  return (
    <FieldPopover open={true} anchorEl={anchorEl || undefined} sx={{ width: 400, p: 0 }} onClose={cancel}>
      <CustomLabel
        customLabelValue={measure.config.customLabel}
        originalName={measure.meta.caption}
        updateCustomLabel={updateCustomLabel}
      />
      <OptionDivider />
      {isTheFieldMeasure && <ExplainMenuItem measure={measure} onClose={cancel} />}
      {isTheFieldMeasure && measure.meta.capabilities.isConfigurable && (
        <>
          {measure.meta.capabilities.chartOfAccountsAllowed && (
            <ChartOfAccountsItem measure={measure} onClose={cancel} />
          )}
          <OptionDivider />
          <Grid
            sx={{
              display: "flex",
              flexDirection: "column",
              flex: 1,
              gap: 1.5,
              px: 2,
              py: 1.5,
            }}
          >
            {measure.meta.capabilities.dateRangeCustomization && (
              <PeriodMenuItem measure={measure} saveChanges={saveChanges} onClose={cancel} />
            )}
            {measure.meta.capabilities.transferredBalanceAllowed && (
              <TransferFieldMenuItem measure={measure} onUpdate={saveChanges} />
            )}
            {showAmountRelatedMeasures && (
              <>
                <AmountTypeMenuItem measure={measure} saveChanges={saveChanges} disabled={amountTypeChangeDisabled} />
                <ValueFieldsMenuItem measure={measure} saveChanges={saveChanges} />
              </>
            )}
            <CashFlowFrequencyMenuItem measure={measure} saveChanges={saveChanges} onClose={cancel} />
          </Grid>
          <OptionDivider />
          <Stack py={1.5}>
            <Typography variant="subtitle1" sx={{ pl: 2, py: 1.5 }}>
              Other
            </Typography>
            <CustomConditionsMenuItem measure={measure} saveAllowCustomConditions={saveAllowCustomConditions} />
            <BlankZeroMenuItem measure={measure} saveChanges={saveChanges} />
            <ReverseSignMenuItem measure={measure} saveChanges={saveChanges} />
            <SuppressBlankValues
              field={measure}
              onChanged={handleSuppressValues}
              showExcludeZeroValues={measure.config.hideBlankValues === true}
            />
          </Stack>
        </>
      )}
      <OptionDivider />
      <FieldOptionDeleteButton onRemove={handleRemove} />
    </FieldPopover>
  );
}

const OptionDivider = () => <Divider style={{ marginBottom: 0, marginTop: 0 }} />;
