import { BiAggregation, ReportField } from "../../../../shared/reporting/api/biClient.types";

export function getAggregationTypes() {
  const items = [
    { value: BiAggregation.None, text: "None", short: "None" },
    { value: BiAggregation.Count, text: "Count", short: "Count" },
    { value: BiAggregation.Average, text: "Average", short: "Avg" },
    { value: BiAggregation.Min, text: "Min", short: "Min" },
    { value: BiAggregation.Max, text: "Max", short: "Max" },
    { value: BiAggregation.Sum, text: "Sum", short: "Sum" },
    { value: BiAggregation.StringAgg, text: "Concatenate Values", short: "ConcVal" },
  ];

  return items;
}

export function findAggregationTypes(types: BiAggregation[]) {
  return getAggregationTypes().filter((r) => types.includes(r.value));
}

export function getAggregationType(aggregationType: BiAggregation) {
  const found = getAggregationTypes().find((r) => r.value === aggregationType);
  return found;
}

export const getDimensionAggregationType = (field: ReportField) => {
  //TODO: uncomment when add more aggregation types
  return undefined;
  if (!field.config.aggregation) {
    return undefined;
  }
  if (field.config.aggregation === BiAggregation.None) {
    return undefined;
  }

  return field.config.aggregation;
};
