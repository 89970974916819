import {
  AreaItemType,
  DimensionDescriptor,
  DimensionField,
  DimensionFieldType,
  FieldConfigurationType,
  Grouping,
  ItemDataType,
  MeasureDescriptor,
  MetaItemType,
} from "../../../../../shared/reporting/api/biClient.types";
import cloneDeep from "../../../../../shared/utilities/cloneDeep";
import { generateGuid } from "../../../../../shared/utilities/generateGuid";
import { formatDate } from "../../../../utilities/Utilities";
import { referenceDateFields } from "../../tabular/utilities/referenceDateFields";
import { ConditionField } from "../../Types";
import { MeasureUtils } from "../../utils/MeasureUtils";
import { toAmountType, toCalculateBy } from "../customMeasure/utilities/measureConverter";
import { createFieldId } from "./createFieldId";

const DEFAULT_DATE_GROUPING = Grouping.ByYear;

export function createConditionField(meta: DimensionDescriptor) {
  const metaClone = cloneDeep(meta);
  const values = metaClone.type === ItemDataType.Date ? [formatDate(new Date())] : [];
  const field: ConditionField = {
    meta: metaClone,
    config: { guid: createFieldId(meta.name), filter: { dimensionName: metaClone.name, values }, parameter: true },
    areaItemType: AreaItemType.CONDITIONS,
  };
  return field;
}

export function createTabularField(meta: DimensionDescriptor | MeasureDescriptor) {
  const metaClone = cloneDeep(meta);
  if (metaClone.itemType === MetaItemType.DIMENSION) {
    return <DimensionField>{
      type: DimensionFieldType.DIMENSION,
      areaItemType: AreaItemType.FIELDS,
      meta: metaClone as DimensionDescriptor,
      config: {
        type: FieldConfigurationType.Dimension,
        guid: createFieldId(metaClone.name),
        name: metaClone.name,
        grouping:
          metaClone.type === ItemDataType.Date && referenceDateFields.includes(meta.name)
            ? DEFAULT_DATE_GROUPING
            : undefined,
        customLabel: undefined,
        aggregation: undefined,
      },
    };
  }
  const measureField = MeasureUtils.createValueField(metaClone as MeasureDescriptor, AreaItemType.FIELDS);
  measureField.config.calculateByField = toCalculateBy(measureField.meta.units);
  measureField.config.amountType = toAmountType(measureField.meta.units);
  if (!measureField.config.format && measureField.meta.defaultFormat?.formatType === "Numeric") {
    measureField.config.format = measureField.meta.defaultFormat;
  }

  return measureField;
}

export function createMeasureField(meta: MeasureDescriptor) {
  const metaClone = cloneDeep(meta);
  const valueField = MeasureUtils.createValueField(metaClone, AreaItemType.VALUES);
  if (!valueField.config.format && valueField.meta.defaultFormat?.formatType === "Numeric") {
    valueField.config.format = valueField.meta.defaultFormat;
  }
  return valueField;
}

export function createRowField(meta: DimensionDescriptor) {
  const metaClone = cloneDeep(meta);
  const cloneField: DimensionField = {
    type: DimensionFieldType.DIMENSION,
    meta: metaClone,
    areaItemType: AreaItemType.ROWS,
    config: {
      type: FieldConfigurationType.Dimension,
      name: metaClone.name,
      displayValues: metaClone.displayValues,
      grouping: metaClone.type === ItemDataType.Date ? DEFAULT_DATE_GROUPING : undefined,
      customLabel: undefined,
      aggregation: undefined,
      guid: generateGuid(),
    },
  };
  return cloneField;
}

export function createColumnField(meta: DimensionDescriptor) {
  const metaClone = cloneDeep(meta);
  const columnField: DimensionField = {
    type: DimensionFieldType.DIMENSION,
    meta: metaClone,
    areaItemType: AreaItemType.COLUMNS,
    config: {
      type: FieldConfigurationType.Dimension,
      guid: generateGuid(),
      name: metaClone.name,
      displayValues: metaClone.displayValues,
      grouping: metaClone.type === ItemDataType.Date ? DEFAULT_DATE_GROUPING : undefined,
      customLabel: undefined,
      aggregation: undefined,
    },
  };
  return columnField;
}

export function createArgument(meta: DimensionDescriptor) {
  const metaClone = cloneDeep(meta);
  const argumentField: DimensionField = {
    type: DimensionFieldType.DIMENSION,
    meta: metaClone,
    areaItemType: AreaItemType.ARGUMENTS,
    config: {
      type: FieldConfigurationType.Dimension,
      guid: generateGuid(),
      name: metaClone.name,
      grouping: metaClone.type === ItemDataType.Date ? DEFAULT_DATE_GROUPING : undefined,
      customLabel: undefined,
    },
  };
  return argumentField;
}
