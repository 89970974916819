import objectHash from "object-hash";
import React, { PropsWithChildren } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  ChartConfiguration,
  DimensionDescriptor,
  MeasureDescriptor,
  Report,
  ReportConfiguration,
  ReportType,
} from "../../../../shared/reporting/api/biClient.types";
import { currentReportActions, selectReportConfiguration } from "../../../store/currentReportSlice";
import { selectDimensions, selectMeasures } from "../../../store/metaDataSlice";
import useCrossFiltering from "../common/hooks/useCrossFiltering";
import useDeferredDictionaryLoading from "../common/hooks/useDeferredDictionaryLoading";
import {
  configurationToConditions,
  configurationToSorts,
  configurationToValues,
} from "../pivot/utilities/Configurations";
import { updateValues } from "../utils/MeasureUtils";
import { FieldsStateContextProvider, FieldsStateContextType } from "./contexts/FieldsStateContext";
import useFieldsState, { FieldsStateReturnType } from "./hooks/useFieldsState";
import { configurationToArguments, createReportConfiguration } from "./utilities/configurations";

interface Props {
  report: Report;
}
export default function ChartContainer({ report, children }: PropsWithChildren<Props>) {
  const dispatch = useDispatch();
  const dimensions = useSelector(selectDimensions);
  const measures = useSelector(selectMeasures);
  const reportConfiguration = useSelector(selectReportConfiguration);
  const fieldsState = useFieldsState();
  const settingsHash = objectHash(fieldsState.settings);
  const fieldsStateRef = React.useRef(fieldsState);
  fieldsStateRef.current = fieldsState;

  React.useEffect(
    () => updateReportType(report, fieldsStateRef.current),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [report?.reportType]
  );

  React.useEffect(
    () => setReportConfiguration(report, fieldsStateRef.current, dimensions, measures, reportConfiguration),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [report?.reportId]
  );

  React.useEffect(() => updateValues(fieldsStateRef.current, measures), [measures]);

  React.useEffect(() => {
    const configuration = createReportConfiguration(
      fieldsState.conditions,
      fieldsState.arguments,
      fieldsState.values,
      fieldsState.sorts,
      fieldsState.settings
    );
    dispatch(currentReportActions.updateReportConfiguration(configuration));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fieldsState.conditions, fieldsState.arguments, fieldsState.values, fieldsState.sorts, settingsHash]);

  const context = React.useMemo(() => buildContext(fieldsState), [fieldsState]);

  useCrossFiltering(context.conditionsArea);
  useDeferredDictionaryLoading(context.conditionsArea);

  return <FieldsStateContextProvider {...context}>{children}</FieldsStateContextProvider>;
}

function updateReportType(report: Report, fieldsState: FieldsStateReturnType) {
  if (
    report?.reportType === ReportType.PieChart ||
    report?.reportType === ReportType.BarChart ||
    report?.reportType === ReportType.LineChart ||
    report?.reportType === ReportType.AreaChart ||
    report?.reportType === ReportType.DoughnutChart
  ) {
    fieldsState.updateReportTypeSettings(report.reportType);
  } else {
    fieldsState.updateReportTypeSettings(ReportType.BarChart);
  }
}

function setReportConfiguration(
  report: Report,
  fieldsState: FieldsStateReturnType,
  dimensions: DimensionDescriptor[],
  measures: MeasureDescriptor[],
  currentReportConfiguration: ReportConfiguration | undefined
) {
  const configuration = (currentReportConfiguration || report.configuration) as ChartConfiguration;
  if (!configuration) {
    return;
  }
  if (configuration.conditions) {
    const result = configurationToConditions(configuration.conditions, dimensions);
    fieldsState.setConditions(result);
  }
  if (configuration.arguments) {
    const result = configurationToArguments(configuration.arguments, dimensions);
    fieldsState.setArguments(result);
  }
  if (configuration.values) {
    const result = configurationToValues(configuration.values, measures);
    fieldsState.setMeasures(result);
  }
  if (configuration.sort) {
    const result = configurationToSorts(configuration.sort, dimensions);
    fieldsState.setSorting(result);
  }
  if (configuration.settings) {
    fieldsState.setSettings(configuration.settings);
  }
}

function buildContext(fieldsState: FieldsStateReturnType): FieldsStateContextType {
  const context: FieldsStateContextType = {
    conditionsArea: {
      values: fieldsState.conditions,
      addItem: fieldsState.addCondition,
      removeItem: fieldsState.removeCondition,
      moveItem: fieldsState.moveCondition,
      updateItem: fieldsState.updateCondition,
      updateItemConfig: fieldsState.updateConditionConfig,
    },
    argumentsArea: {
      values: fieldsState.arguments,
      addItem: fieldsState.addArgument,
      removeItem: fieldsState.removeArgument,
      moveItem: fieldsState.moveArgument,
      updateItem: fieldsState.updateArgument,
      updateItemConfig: fieldsState.updateArgumentConfig,
    },
    valuesArea: {
      values: fieldsState.values,
      addItem: fieldsState.addMeasure,
      removeItem: fieldsState.removeMeasure,
      moveItem: fieldsState.moveMeasure,
      updateItem: fieldsState.updateMeasure,
      updateItemConfig: fieldsState.updateMeasureConfig,
    },
    sortingArea: {
      values: fieldsState.sorts,
      addItem: fieldsState.addSorting,
      removeItem: fieldsState.removeSorting,
      moveItem: fieldsState.moveSorting,
      updateItem: fieldsState.updateSorting,
      updateItemConfig: fieldsState.updateSortingConfig,
      setSorting: fieldsState.setSorting,
    },
    settingsArea: {
      settings: fieldsState.settings,
      update: fieldsState.updateSettings,
      updateLegend: fieldsState.updateLegendSettings,
      updateArgumentAxis: fieldsState.updateArgumentAxisSettings,
      updateValueAxis: fieldsState.updateValueAxisSettings,
      updateSerie: fieldsState.updateSeriesSettings,
      addSerie: fieldsState.addSerie,
      removeSerie: fieldsState.removeSerie,
      updateSerieAxis: fieldsState.updateSerieValueAxis,
    },
  };
  return context;
}
