import { Box, Typography } from "@mui/material";
import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  DimensionField,
  FieldConfiguration,
  MeasureField,
  ReportField,
} from "../../../../../../shared/reporting/api/biClient.types";
import { selectDimensions } from "../../../../../store/metaDataSlice";
import DropFieldContainer from "../../../common/fields/DropFieldContainer";
import { DraggableFieldType, DraggableMetaType, FieldWrapper } from "../../../common/fields/types/dropField.types";
import { createMeasureField } from "../../../common/utilities/createFields";
import { extractGuid, extractMeta } from "../../../common/utilities/dropFieldContainerHelper";
import { ShowFieldOptionsSettings } from "../../../Types";
import { formatDimensionFieldCaption } from "../../../utils/formatDimensionFieldCaptions";
import { isMeasureConfigurationValid } from "../../../utils/isConfigurationValid";
import { isDimension, isMeasure } from "../../../utils/isMeasure";
import { getCustomConditionsChanges } from "../../../utils/MeasureUtils";
import { ConditionFieldsArea, ValueFieldsArea } from "../../types";
import { formatMeasureCaption } from "./helper";
import MeasureFieldOptionPopup from "./MeasureFieldOptionPopup";
import MeasureItem from "./MeasureItem";

interface Props {
  conditionsArea: ConditionFieldsArea;
  valuesArea: ValueFieldsArea;
  fields: DimensionField[];
  canDropItem?: () => boolean;
}

export const MeasuresDropFieldsContainer = ({ conditionsArea, valuesArea, fields, canDropItem }: Props) => {
  const dimensions = useSelector(selectDimensions);

  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [optionMeasure, setOptionMeasure] = useState<ReportField>();

  useEffect(() => {
    const measure = valuesArea.values.find((v) => v.config.guid === optionMeasure?.config.guid);
    if (isMeasure(measure)) {
      setOptionMeasure(measure);
    }
  }, [valuesArea.values, optionMeasure]);

  const showOptions = useCallback((settings: ShowFieldOptionsSettings<ReportField>) => {
    setOptionMeasure(settings.field);
    setAnchorEl(settings.ref);
  }, []);

  const saveChanges = useCallback(
    (changes: Partial<FieldConfiguration>, causeClosing: boolean) => {
      if (optionMeasure === undefined) return;
      valuesArea.updateItemConfig(optionMeasure, changes);
      if (causeClosing) setAnchorEl(null);
    },
    [valuesArea, optionMeasure]
  );

  const applyAllowCustomConditions = useCallback(
    (useCustomConditions: boolean) => {
      if (optionMeasure !== undefined) {
        valuesArea.updateItemConfig(optionMeasure, getCustomConditionsChanges(useCustomConditions));
      }
    },
    [valuesArea, optionMeasure]
  );

  const measureCaptionColor = useCallback(
    (value: MeasureField) => {
      const valid = isMeasureConfigurationValid(conditionsArea.values, fields, dimensions, value);
      return valid.valid ? undefined : "error.main";
    },
    [conditionsArea.values, dimensions, fields]
  );
  const formatCaption = useCallback(
    (field: ReportField) => {
      if (isMeasure(field)) {
        return formatMeasureCaption(field, measureCaptionColor(field));
      } else if (isDimension(field)) {
        return formatDimensionFieldCaption(field);
      }
      return undefined;
    },
    [measureCaptionColor]
  );

  const handleCanDropItem = useCallback(() => {
    return canDropItem ? canDropItem() : true;
  }, [canDropItem]);

  const handleCancel = useCallback(() => {
    setAnchorEl(null);
  }, []);

  return (
    <Box sx={{ display: "flex", flexDirection: "column", gap: ".5rem" }}>
      <Typography variant="subtitle2" sx={{ color: "text.primary" }}>
        Values
      </Typography>
      <DropFieldContainer
        areaFieldType="VALUES"
        acceptedDropTypes={["MEASURE"]}
        fields={valuesArea.values}
        canDropItem={handleCanDropItem}
        renderListItem={(props) => (
          <MeasureItem
            {...props}
            fields={dimensions}
            globalConditions={conditionsArea.values}
            showConditionParameter
            updateMeasure={valuesArea.updateItemConfig}
          />
        )}
        getKeyValue={getKeyValue}
        formatCaption={formatCaption}
        onAddItem={valuesArea.addItem}
        onRemoveItem={valuesArea.removeItem}
        onMoveItem={valuesArea.moveItem}
        showOptions={showOptions}
        createListItem={createMeasureField}
      />
      {anchorEl && optionMeasure && (
        <MeasureFieldOptionPopup
          anchorEl={anchorEl}
          measure={optionMeasure}
          cancel={handleCancel}
          saveChanges={saveChanges}
          saveAllowCustomConditions={applyAllowCustomConditions}
          onRemove={() => valuesArea.removeItem(optionMeasure)}
        />
      )}
    </Box>
  );
};

function getKeyValue(field: FieldWrapper<DraggableFieldType> | DraggableMetaType) {
  const guid = extractGuid(field);
  if (guid) {
    return guid;
  }
  const meta = extractMeta(field);
  if (meta) {
    return meta.name;
  }
  return "";
}

export default MeasuresDropFieldsContainer;
