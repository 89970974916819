import { Switch, Typography } from "@mui/material";
import { useCallback } from "react";
import { MenuItemStyled } from "../../../../../shared/components/MenuItemStyled";
import {
  BiAggregation,
  DimensionField,
  FieldConfiguration,
  FieldConfigurationType,
  MeasureDateRange,
} from "../../../../../shared/reporting/api/biClient.types";

interface Props {
  field: DimensionField;
  onChange: (value: Partial<FieldConfiguration>) => void;
}

export default function AggregateStringComponent({ field, onChange }: Props) {
  const value = field.config.aggregation === BiAggregation.StringAgg;

  const onChangeAggregation = useCallback(
    (value: boolean) => {
      const obj: Partial<FieldConfiguration> = {};
      if (value) {
        obj.aggregation = BiAggregation.StringAgg;
        obj.dateRange = MeasureDateRange.EndingBalance;
        obj.type = FieldConfigurationType.Aggregation;
      } else {
        obj.aggregation = BiAggregation.None;
        obj.type = FieldConfigurationType.Dimension;
      }
      onChange(obj);
    },
    [onChange]
  );

  return (
    <MenuItemStyled
      sx={{ display: "flex", flex: 1, flexDirection: "row", gap: 1 }}
      onClick={() => onChangeAggregation(!value)}
    >
      <Typography sx={{ display: "flex", flex: 1 }}>Combine Multiple Values in One Cell</Typography>
      <Switch checked={value} />
    </MenuItemStyled>
  );
}
