import { Grid, Select, Typography } from "@mui/material";
import HorizontalFill from "../../../../../shared/components/HorizontalFill";
import { MenuItemStyled } from "../../../../../shared/components/MenuItemStyled";
import { BiAggregation, DimensionField, Grouping } from "../../../../../shared/reporting/api/biClient.types";
import { defaultDateGrouping, getDateGroupingLabel } from "../../utils/captionsHelper";

interface Props {
  field: DimensionField;
  updateGrouping: (newGrouping: Grouping | undefined) => void;
}

const DateGroupingComponent = ({ field, updateGrouping }: Props) => {
  const isRangeSelectionDisabled = !!field.config.aggregation && field.config.aggregation !== BiAggregation.None;

  return (
    <Grid container sx={{ alignItems: "center", px: 2, py: 1.5 }}>
      <Typography color="secondary">Date Grouping</Typography>
      <HorizontalFill />
      <Select
        value={field.config.grouping}
        disabled={isRangeSelectionDisabled}
        sx={{ width: 208, ".MuiSelect-select": { py: 0.7 } }}
      >
        {defaultDateGrouping.map((item) => (
          <MenuItemStyled
            key={item || "none"}
            value={item}
            selected={item === field.config.grouping}
            onClick={() => updateGrouping(item)}
          >
            <Typography variant="caption">{getDateGroupingLabel(item)}</Typography>
          </MenuItemStyled>
        ))}
      </Select>
    </Grid>
  );
};

export default DateGroupingComponent;
