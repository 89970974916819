import { useCallback } from "react";
import { FieldConfiguration, Grouping, ItemDataType } from "../../../../../shared/reporting/api/biClient.types";
import CustomLabel from "../../../common/customLabel/CustomLabel";
import FieldOptionDeleteButton from "../../../common/FieldOptionDeleteButton";
import FieldOptionsDivider from "../../../common/FieldOptionsDivider";
import AggregateStringComponent from "../../common/fields/AggregateStringComponent";
import FieldPopover from "../../common/fields/FieldPopover";
import { GeneralFieldOptionPopupProps } from "../../common/fields/types/generalFieldOptionPopup.types";
import SuppressBlankValues from "../../common/SuppressBlankValues";
import { referenceDateFields } from "../utilities/referenceDateFields";
import DateGroupingComponent from "./DateGroupingComponent";

export default function GeneralFieldOptionPopup({
  anchorEl,
  field,
  cancel,
  onRemove,
  onUpdateConfiguration,
  onUpdateCustomLabel,
}: GeneralFieldOptionPopupProps) {
  const isReferencedDateField = referenceDateFields.includes(field.meta.name);
  const isNumeric = field.meta.type === ItemDataType.Numeric;
  const showGroupings = referenceDateFields.includes(field.meta.name);

  const handleRemove = () => {
    onRemove();
    cancel();
  };

  const updateGrouping = useCallback(
    (newGrouping: Grouping | undefined) => {
      if (field.config.grouping !== newGrouping) {
        onUpdateConfiguration({ grouping: newGrouping }, false);
      }
    },
    [field.config.grouping, onUpdateConfiguration]
  );

  const handleSuppressValues = useCallback(
    (changes: Partial<FieldConfiguration>) => {
      onUpdateConfiguration(changes, false);
    },
    [onUpdateConfiguration]
  );

  const updateAggregation = useCallback(
    (value: Partial<FieldConfiguration>) => {
      onUpdateConfiguration(value, false);
    },
    [onUpdateConfiguration]
  );

  return (
    <FieldPopover open={!!anchorEl} anchorEl={anchorEl} onClose={cancel} sx={{ width: 400, p: 0 }}>
      <CustomLabel
        customLabelValue={field.config.customLabel}
        originalName={field.meta.caption}
        updateCustomLabel={onUpdateCustomLabel}
      />
      <FieldOptionsDivider />
      <AggregateStringComponent field={field} onChange={updateAggregation}></AggregateStringComponent>
      {/* <DimensionAggregationComponent field={field} onChange={updateAggregation}></DimensionAggregationComponent> */}
      {!isReferencedDateField && (
        <>
          <SuppressBlankValues
            field={field}
            onChanged={handleSuppressValues}
            showExcludeZeroValues={field.config.hideBlankValues === true && isNumeric}
          />
        </>
      )}
      {showGroupings && (
        <>
          <DateGroupingComponent field={field} updateGrouping={updateGrouping} />
        </>
      )}
      <FieldOptionsDivider />
      <FieldOptionDeleteButton onRemove={handleRemove} />
    </FieldPopover>
  );
}
