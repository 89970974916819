import { ConditionConfiguration, GroupingField, ReportField } from "../../../../../shared/reporting/api/biClient.types";
import cloneDeep from "../../../../../shared/utilities/cloneDeep";
import { ConditionField, SortField } from "../../Types";
import { resetMandatoryField } from "../../utils/conditionsUtils";
import { isMeasure } from "../../utils/isMeasure";

export const removeConditionItem = (item: ConditionField, fields: ConditionField[]) => {
  const fieldIndex = fields.findIndex((field) => field.config.guid === item.config.guid);
  if (fieldIndex > -1) {
    const copy = [...fields];
    copy.splice(fieldIndex, 1);
    return copy;
  }
  return fields;
};

export const removeMeasureItem = (item: ReportField, fields: ReportField[]) => {
  const fieldIndex = fields.findIndex((field) => field.config.guid === item.config.guid);
  if (fieldIndex > -1) {
    const copy = [...fields];
    copy.splice(fieldIndex, 1);
    return copy;
  }
  return fields;
};

type Field = {
  config: { guid: string };
};

export const removeItem = <T extends Field>(item: T, fields: T[]) => {
  const fieldIndex = fields.findIndex((field) => field.config.guid === item.config.guid);
  if (fieldIndex > -1) {
    const copy = [...fields];
    copy.splice(fieldIndex, 1);
    return copy;
  }
  return fields;
};

export const removeItemV2 = <T>(item: T, fields: T[]) => {
  const fieldIndex = fields.indexOf(item);
  if (fieldIndex > -1) {
    const copy = [...fields];
    copy.splice(fieldIndex, 1);
    return copy;
  }
  return fields;
};

export const removeSortingItem = (item: SortField, fields: SortField[]) => {
  const fieldIndex = fields.findIndex((field) => field.config.name === item.config.name);
  if (fieldIndex > -1) {
    const copy = [...fields];
    copy.splice(fieldIndex, 1);
    return copy;
  }
  return fields;
};

export const removeGroupItem = (item: GroupingField, fields: GroupingField[]) => {
  const fieldIndex = fields.findIndex((field) => field.name === item.name);
  if (fieldIndex > -1) {
    const copy = [...fields];
    copy.splice(fieldIndex, 1);
    return copy;
  }
  return fields;
};

export const moveItem = <T extends Field>(field: T, newIndex: number, fields: T[]) => {
  const copy = [...fields];
  const index = copy.findIndex((c) => c.config.guid === field.config.guid);
  if (index > -1) {
    copy.splice(index, 1);
    copy.splice(newIndex, 0, field);
    return copy;
  }
  return fields;
};

export const moveConditionItem = (field: ConditionField, newIndex: number, fields: ConditionField[]) => {
  const index = fields.findIndex((condition) => condition.config.guid === field.config.guid);
  if (index > -1 && index !== newIndex) {
    const fieldToSet = fields[index] || field;
    fields.splice(index, 1);
    fields.splice(newIndex, 0, fieldToSet);
  }
  return fields;
};

export const moveMeasureItem = (field: ReportField, newIndex: number, fields: ReportField[]) => {
  const index = fields.findIndex((condition) => condition.config.guid === field.config.guid);
  if (index > -1 && index !== newIndex) {
    const fieldToSet = fields[index] || field;
    fields.splice(index, 1);
    fields.splice(newIndex, 0, fieldToSet);
  }
  return fields;
};

export const moveSortingItem = (field: SortField, newIndex: number, fields: SortField[]) => {
  const copy = [...fields];
  const index = copy.findIndex((condition) => condition.config.name === field.config.name);
  if (index > -1) {
    copy.splice(index, 1);
    copy.splice(newIndex, 0, field);
    return copy;
  }
  return fields;
};

export const moveGroupItem = (field: GroupingField, newIndex: number, fields: GroupingField[]) => {
  const copy = [...fields];
  const index = copy.findIndex((condition) => condition.name === field.name);
  if (index > -1) {
    copy.splice(index, 1);
    copy.splice(newIndex, 0, field);
    return copy;
  }
  return fields;
};

export const moveItemV2 = <T>(field: T, newIndex: number, fields: T[]) => {
  const copy = [...fields];
  const index = copy.indexOf(field);
  if (index > -1) {
    copy.splice(index, 1);
    copy.splice(newIndex, 0, field);
    return cloneDeep(copy);
  }
  return fields;
};

export const updateItem = <T>(key: keyof T, item: T, changedValues: Partial<T>, fields: T[]) => {
  const field = fields.find((condition) => condition[key] === item[key]);
  if (field !== undefined) {
    const copy = [...fields];
    const index = copy.indexOf(field);
    const updatedField: T = { ...field, ...changedValues };
    copy[index] = updatedField;
    return copy;
  }
  return fields;
};

export const updateMeasureItem = (item: ReportField, changedValues: Partial<ReportField>, fields: ReportField[]) => {
  const field = fields.find((field) => field.config.guid === item.config.guid);
  if (field !== undefined) {
    const copy = [...fields];
    const index = copy.indexOf(field);
    const updatedField = { ...field, ...changedValues } as ReportField;
    copy[index] = updatedField;
    return copy;
  }
  return fields;
};

export const updateSortingItem = (item: SortField, changedValues: Partial<SortField>, fields: SortField[]) => {
  const field = fields.find((field) => field.config.name === item.config.name);
  if (field !== undefined) {
    const copy = [...fields];
    const index = copy.indexOf(field);
    const updatedField: SortField = { ...field, ...changedValues };
    copy[index] = updatedField;
    return copy;
  }
  return fields;
};

export const updateConditionItem = (
  item: ConditionField,
  changedValues: Partial<ConditionField>,
  fields: ConditionField[]
) => {
  const field = fields.find((condition) => condition.config.guid === item.config.guid);
  if (field !== undefined) {
    Object.assign(field, changedValues);
  }
  return fields;
};

export const updateItemV2 = <T>(field: T, changedValues: Partial<T>, fields: T[]) => {
  const copy = [...fields];
  const index = copy.indexOf(field);
  const updatedField: T = { ...field, ...changedValues };
  copy[index] = updatedField;
  return copy;
};

export const removeConditionFromLinked = (guid: string, measures: ReportField[]) => {
  measures.forEach((measure) => {
    if (!measure.config.linkedConditions) return;

    const index = measure.config.linkedConditions.indexOf(guid);
    if (index > -1) {
      const copy = [...measure.config.linkedConditions];
      copy.splice(index, 1);
      measure.config.linkedConditions = copy;
    }
  });
  return measures;
};

export const isConditionValid = (field: ConditionField, conditions: ConditionField[]) => {
  if (field.hasLinks) return true;
  const linkedConditions = conditions.filter(
    (v) => v.meta.name === field.meta.name && v.config.guid !== field.config.guid && v.hasLinks
  );
  if (linkedConditions.length > 0) return false;

  const notLinkedConditions = conditions.filter((v) => v.meta.name === field.meta.name && !v.hasLinks);
  if (notLinkedConditions.length === 1) return true;

  return notLinkedConditions.indexOf(field) === 0;
};

export const updateLinkAndValidate = (conditions: ConditionField[], measures: ReportField[]) => {
  const copy = cloneDeep(conditions);
  copy.forEach((condition) => {
    const index = measures.findIndex(
      (v) =>
        isMeasure(v) &&
        v.meta.capabilities.customConditionsAllowed &&
        v.config.useCustomConditions &&
        v.config.linkedConditions &&
        v.config.linkedConditions.indexOf(condition.config.guid) > -1
    );
    condition.hasLinks = index > -1;
  });
  copy.forEach((condition) => {
    condition.invalid = !isConditionValid(condition, copy);
  });

  return copy;
};

export const calculateAndAssignSystemLabel = (item: ConditionField, conditions: ConditionField[]) => {
  const sameItems = conditions.filter((f) => f.meta.name === item.meta.name && !f.config.customLabel);
  if (sameItems.length > 0) {
    sameItems
      .filter((si) => !si.config.systemLabel)
      .forEach((f, index) => {
        f.config.systemLabel = `${f.meta.caption} ${index + 1}`;
      });

    const systemLabels = sameItems
      .filter((si) => !!si.config.systemLabel)
      .map((f) => f.config.systemLabel)
      .filter((label): label is string => !!label);
    if (systemLabels.length > 0) {
      let index = 1;
      systemLabels.forEach((label) => {
        const spaceIndex = label.lastIndexOf(" ");
        if (spaceIndex > -1) {
          const parsedValue = parseInt(label.substring(spaceIndex));
          if (!isNaN(parsedValue)) {
            index = Math.max(index, parsedValue);
          }
        }
        item.config.systemLabel = `${item.meta.caption} ${index + 1}`;
      });
    } else {
      item.config.systemLabel = `${item.meta.caption} ${sameItems.length + 1}`;
    }
  }
};

export const updateConditionConfig = (
  fieldConfiguration: ConditionConfiguration,
  changes: Partial<ConditionConfiguration>
) => {
  return { ...fieldConfiguration, ...resetMandatoryField(fieldConfiguration, changes) };
};

export const getGroupMetaNames = (grouping: GroupingField[], fields: ReportField[]) => {
  return grouping.map((g) => fields.find((f) => f.config.guid === g.name)?.meta?.name ?? "");
};
